import React from "react"

const Dashboard = ()=>{
    return(
      <div className="content">

      <h1>Dashboard Dashboard Dashboard</h1>        
      </div>
    )
}
export default Dashboard;