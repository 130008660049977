import React, { Component } from "react";
import "../../styles/WorkspaceStyle.css"

export class Titlebar extends Component {
    
    render(){
        return(
        <div className="titlebar">
            <label>Dashboard</label>
        </div>
        )
    }
}